.btn-clear{
    color: rgba(85, 86, 87, 0.5);
    background-color: transparent;
    border-color: transparent;
}

.bg-light-grey {
    background: #f9f9f9 !important;
}

.text-light-grey {
    color: #999;
}

/* APP LOADER */

.loading-overlay {
    z-index: 2000;
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}
.sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #e6aead;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.form-group-btn .form-control {
    padding-right: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* PAGE LOADER */
.pagebody-loader{
    height: calc(100vh - 140px);
    width: 100vw;
    display: flex;
    align-items: center;
}


/* PAGE LOADER ENDS HERE */

/* APP LOADER */

/*datepicker*/
.react-datepicker-wrapper{
    display: block;
}
.react-datepicker__input-container{
    display: block;
}

.custom-edit-button{
    font-size: 16px;
    vertical-align: bottom;
}
.form-group-btn .append-left .input-group-append {
    left: 0;
    right: auto;
}
.form-group-btn .append-left .form-control {
    padding-left: 55px;
}
.form-group-btn .input-group-append {
    position: absolute;
    right: 0;
    z-index: 9;
}
.form-group-btn .input-group-append .btn {
    text-transform: uppercase;
    font-weight: 700;
}
.form-group-btn .input-group-append.append-left {
    left: 0;
    right: auto;
}
.form-group-btn .form-control {
    padding-right: 90px;
}

.input-group>.custom-select:not(:first-child), .input-group>.form-control:not(:first-child), .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child), .input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text{
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    border-radius: 0.5rem;
}

.nannies-list .nannies-avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.nannies-list strong{
    font-weight: 500;
}
.nannies-list h5{
    font-weight: 600;
    font-size: 1.25em;
}
.nannies-list p{
    margin-bottom: .25rem;
}
.cus-chk .cus-chk-item input[type="checkbox"] + label.btn:hover{
    color: #f87ca3 !important;
}
.cus-chk .cus-chk-item input[type="checkbox"] + label{
    color: #f87ca3 !important;
    background-color: transparent;
    background-image: none;
    border-color: #f87ca3;
}

.cus-chk .cus-chk-item input[type="checkbox"]:checked + label{
    color: #ffffff !important;
    background-color: #f87ca3;
    background-image: none;
    border-color: #f87ca3;
}
.cus-chk .cus-chk-item input[type="checkbox"]:checked + label.btn:hover{
    color: #ffffff !important;
}

select.form-control:not([size]):not([multiple]){
    height: calc(2.25rem + 2px);
}
select.form-control.rounded-0.rounded-right{
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
}
@media (max-width: 560px){
    .res-input-group-flex{
        flex-wrap: wrap;
        justify-content: center;
    }
}

/* fix = code from bootstrap 3 */

.tr-loop>tr:not(.toggle-row) {
    display: none;
}

.show-detail table tr:first-child td{
    border-top: 0 !important;
}

.no-border-first-row thead tr:first-child th{
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.show-detail {
    display: table-row !important;
    -webkit-animation: fadeIn .5s;
    animation: fadeIn .5s;
    background-color: #f9f9f9;
}
.table-scroll>table{
    min-width: 2000px;
}

.searchbar .ic-search{
    position: absolute;
    top: 9px;
    left: 10px;
    z-index: 9;
}
.searchbar .form-control{
    padding-left: 35px;
}

.scroll-main, .scroll-child{width: 100%;
overflow-x: scroll; overflow-y:hidden;}
.scroll-main{height: 15px; }
.scroll-main-inner {width:2000px; height: 15px; }
.scroll-child-inner {width:2000px; min-height: 350px;
overflow: auto;}

::-webkit-scrollbar {
  height: 7px;
  width: 3px;
}
::-webkit-scrollbar-thumb {
  background-color: #fa7ca5;
  -webkit-border-radius: 20px;
  height: 100px;
}
::-webkit-scrollbar-corner {
  background: #fa7ca5;
}
.min-h-300 .dropdown-menu{
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
}
.btn-darkblue {
	background-color: #001eff!important
}
.btn-darkblue:focus,
.btn-darkblue:hover,
button.btn-darkblue:focus,
button.btn-darkblue:hover {
	background-color: #0018cc!important
}

.btn-lightblue {
	background-color: #008aff!important
}

.btn-lightblue:focus,
.btn-lightblue:hover,
button.btn-lightblue:focus,
button.btn-lightblue:hover {
	background-color: #006ecc!important
}